import React, { useEffect, Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { AutoLogin, SilentLogin } from "../AppRedux/Actions/Auth";
import Loader from "./loader";
import { ToastContainer, Slide } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Login = lazy(() => import("../Components/login"));
const MainApp = lazy(() => import("../Components"));

const checkVersionChange = (next) => {
    (async (next) => {
        try {
            if (process.env.REACT_APP_BUILD_NUMBER !== window?.env?.REACT_APP_BUILD_NUMBER) {
                if ('caches' in window) {
                    const { caches } = window;
                    const cache = await caches.keys();
                    await Promise.all(cache.map((key) => caches.delete(key)));
                }
                if ('serviceWorker' in navigator) {
                    const registrations = await navigator.serviceWorker.getRegistrations();
                    registrations.forEach((registration) => { registration.unregister(); });
                }
                next();
            } else {
                next();
            }
        } catch (err) {
            next();
        }
    })(next);
}

const RestrictedRoute = ({ component: Component, token, ...rest }) => (
    <Route {...rest} render={props =>
        token ? <Component {...props} /> : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    } />
);

function getFirstUrl(firtMenu) {
    const { children = [], action, menu_version } = firtMenu ?? {};
    if (children.length > 0 && menu_version === 1) {
        for (let target = 0; target < children.length; target++) {
            return getFirstUrl(children[target]);
        }
    }
    return action;
}

const Theame = (props) => {
    const dispatch = useDispatch();
    const { history, location, match } = props;
    const { authUser, initURL, screenSetting, menus } = useSelector(state => state.auth);
    useEffect(() => {
        const url = new URL(window.location.href.replace("/#!", "").replace("/?", "?"));
        let params = {
            token: url.searchParams.get('token') || false,
            redirect: ('/' + (url.searchParams.get('redirect') || ''))
        };
        checkVersionChange(() => {
            if (params.token) {
                dispatch(AutoLogin(params.redirect, params.token, history));
            } else {
                dispatch(SilentLogin());
            }
        });

    }, [dispatch, history]);

    useEffect(() => {
        if (authUser) {
            if (location.pathname === "/" && !(initURL === "" || initURL === "/" || initURL === "/login")) {
                history.replace(initURL);
            } else if (location.pathname === "/login") {
                history.replace(initURL === "/login" ? "/" : initURL);
            }
            if ((location.pathname || "/") === "/" && menus && menus.length > 0) {
                history.replace(getFirstUrl(menus[0]));
            }
        }
    }, [dispatch, authUser, initURL, location.pathname, history, menus]);

    if (screenSetting) {
        return <Loader />
    }

    return (
        <Suspense fallback={<Loader />}>
            <ToastContainer limit={5} pauseOnHover={false} autoClose={5000} closeButton={true} hideProgressBar={false} transition={Slide} draggable={false} icon={true} />
            <Switch>
                <Route exact path="/login" component={Login} />
                <RestrictedRoute path={`${match.url}`} token={authUser} component={MainApp} />
            </Switch>
        </Suspense>
    );
}

export default Theame;