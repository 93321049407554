/* eslint-disable */
import {
    INIT_URL,
    CHECKING_SCREEN,
    CHECKING_SCREEN_OFF,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    AUTO_SIGNIN_USER_SUCCESS,
    APP_MENUS,
    MENU_SLUG_URL
} from "../ActionTypes/Auth";
import { getToken, getUser } from "../../Util/AuthStates";
const INIT_STATE = {
    loading: false,
    version: global.appVersion,
    alertMessage: "",
    showMessage: false,
    initURL: "",
    screenSetting: true,
    user: getUser(),
    authUser: getToken(),
    menus: [],
    slugs_url: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                authUser: action.payload.token,
                user: action.payload.userData
            };
        }
        case AUTO_SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loading: true,
                authUser: action.payload.token,
                user: action.payload.userData
            };
        }
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            };
        }
        case CHECKING_SCREEN_OFF: {
            return {
                ...state,
                screenSetting: false
            }
        }
        case CHECKING_SCREEN: {
            return {
                ...state,
                screenSetting: true
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                user: {},
                initURL: "/",
                loading: false
            };
        }
        case APP_MENUS: {
            return {
                ...state,
                menus: action.payload
            };
        }
        case MENU_SLUG_URL: {
            return {
                ...state,
                slugs_url: action.payload
            };
        }
        default:
            return state;
    }
};