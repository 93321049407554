import React from "react";
import "./index.css"
import { Provider } from "react-redux";
// import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { store } from './AppRedux/store';
import Theme from "./Theme";
import ThemeSetup from "./Theme/themeOptions";

const App = () => {
  return (
    <Provider store={store}>
      <ThemeSetup>
        {/* <CssBaseline /> */}
        <Router basename="/">
          <Switch>
            <Route path="/" component={Theme} />
          </Switch>
        </Router>
      </ThemeSetup>
    </Provider>
  )
};

export default App;